<ng-template #assignedToPopover>
  @for (username of assignedToUsers; track username) {
    <span class="u_block">{{ username }}</span>
  }
</ng-template>

<div resizeObserver (resizeEvent)="onHeaderResize($event)">
  <div class="app-header u_margin-md-top mb-5">
    <!-- App Title -->
    <h1 class="app-header-title" id="title">Schedules</h1>
  </div>
  <div id="errorBox" [shown]="schedules.apiError" class="madero-style alert alert-danger text-center u_margin-md-top" role="alert">
    <p><strong>{{schedules.errorMessage}}</strong></p>
    {{schedules.apiError}}
  </div>
  <div class="tw-flex tw-flex-col md:tw-items-center md:!tw-flex-row tw-py-8 tw-gap-8 light-border">
    <search-filter #searchFilter class="tw-w-full" [filterConfig]="filterConfig" [search]="search" [doSearch]="schedules.doSearch"></search-filter>
    <a id="scheduleAddButton" class="tw-min-w-80 tw-whitespace-nowrap" mat-flat-button color="primary" uiSref="apps.schedules.add" *requireRole="'cp'">
      <mat-icon svgIcon="plus" class="heavy" />&nbsp;
      Add Schedule
    </a>
  </div>
  <batch-operations [listObject]="schedules"></batch-operations>
</div>
<div
  #schedulesListContainer
  rvSpinner="schedules-list-loader"
  [rvShowSpinner]="schedules.loadingItems">
  <cdk-virtual-scroll-viewport
    #scrollViewport
    itemSize="68"
    class="scrollable-list horizontal-scroll rounded-rows">
    <table id="schedulesListTable" class="table table--schedule-list">
      <thead class="table-header">
        <tr class="table-header__row u_clickable">
          <th class="table-header__cell col-sm-2">
            <div class="flex-row fixed-width">
              <mat-checkbox
                (click)="schedules.selectAll()"
                [checked]="search.selectAll"
                [ariaLabel]="search.selectAll ? 'Deselect all' : 'Select all'"
                *requireRole="'!ap'"
                class="mr-2" />
              <div class="u_clickable" id="tableHeaderName" tabindex="0" (keyup.enter)="schedules.sortBy('name')" (click)="schedules.sortBy('name')">
                Name
                @if (search.sortBy === 'name') {
                  <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa" [ngClass]="{'fa-caret-up': !search.reverse, 'fa-caret-down': search.reverse}"></i>
                }
              </div>
            </div>
          </th>
          <th id="tableHeaderTimeline" class="table-header__cell col-sm-2" tabindex="0" (keyup.enter)="schedules.sortBy('scheduleType', true)" (click)="schedules.sortBy('scheduleType', true)">
            Override
            @if (search.sortBy === 'scheduleType') {
              <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa" [ngClass]="{'fa-caret-up': !search.reverse, 'fa-caret-down': search.reverse}"></i>
            }
          </th>
          <th id="tableHeaderTimeline" class="table-header__cell col-sm-2" tabindex="0" (keyup.enter)="schedules.sortBy('timeline')" (click)="schedules.sortBy('timeline')">
            Timeline
            @if (search.sortBy === 'timeline') {
              <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa" [ngClass]="{'fa-caret-up': !search.reverse, 'fa-caret-down': search.reverse}"></i>
            }
          </th>
          <th id="tableHeaderDistribution" class="table-header__cell col-sm-2" tabindex="0" (keyup.enter)="schedules.sortBy('distributionSummary', true)" (click)="schedules.sortBy('distributionSummary', true)">
            Distribution
            @if (search.sortBy === 'distributionSummary') {
              <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa" [ngClass]="{'fa-caret-up': !search.reverse, 'fa-caret-down': search.reverse}"></i>
            }
          </th>
          <th id="tableHeaderChangeDate" class="table-header__cell col-sm-2" tabindex="0" (keyup.enter)="schedules.sortBy('changeDate')" (click)="schedules.sortBy('changeDate')">
            Last Modified
            @if (search.sortBy === 'changeDate') {
              <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa" [ngClass]="{'fa-caret-up': !search.reverse, 'fa-caret-down': search.reverse}"></i>
            }
          </th>
          @if (companyStateService.isK12Customer() || companyStateService.isHigherEdCustomer()) {
            <th id="tableHeaderAssignedTo" class="table-header__cell col-sm-2" tabindex="0" (keyup.enter)="schedules.sortBy('assignedUsersValue')" (click)="schedules.sortBy('assignedUsersValue')">
              Assigned To
              @if (search.sortBy === 'assignedUsersValue') {
                <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa" [ngClass]="{'fa-caret-up': !search.reverse, 'fa-caret-down': search.reverse}"></i>
              }
            </th>
          }
        </tr>
      </thead>
      <tbody class="table-body">
        @if (!(!schedules.loadingItems && schedules.items.list.length === 0 && search.query === undefined)) {
          <tr *cdkVirtualFor="let schedule of schedules.items.list; let idx = $index"
            uiSref="apps.schedules.details"
            [uiParams]="{scheduleId: schedule.id}"
            class="table-body__row clickable"
            [class.selected]="schedule.selected"
          >
            <td class="table-body__cell">
              <div class="flex-row">
                <mat-checkbox
                  (click)="$event.stopPropagation(); schedules.select(schedule)"
                  [checked]="schedule.selected"
                  [ariaLabel]="schedule.selected ? 'Deselect schedule' : 'Select schedule'"
                  *requireRole="'!ap'"
                  class="mr-2"
                />
                <a uiSref="apps.schedules.details"
                  [uiParams]="{scheduleId: schedule.id}"
                  (click)="$event.stopPropagation()"
                  class="tw-font-medium u_ellipsis-lg">
                  {{schedule.name}}
                </a>
              </div>
            </td>
            <td class="table-body__cell">
              @if (schedule.scheduleTypeName) {
                <mat-chip class="badge"
                  [class.badge-yellow]="schedule.scheduleTypeName.includes('Replace')">
                  {{schedule.scheduleTypeName}}
                </mat-chip>
              }
            </td>
            @if (schedule.expired) {
              <td class="table-body__cell tw-text-red-600">
                Expired
              </td>
            } @else {
              <td class="table-body__cell u_ellipsis-lg tw-text-gray-700" [innerHtml]="schedule.timeline"></td>
            }
            <td class="table-body__cell">
              @if (schedule.distributionSummary === 'No Display Selected') {
                <span class="tw-text-gray-400">
                  －
                </span>
              } @else {
                <mat-chip class="badge">
                  {{schedule.distributionSummary}}
                </mat-chip>
              }
            </td>
            <td class="table-body__cell u_ellipsis-md"><change-details [changeDate]="schedule.changeDate" [changedBy]="schedule.changedBy" /></td>
            @if (companyStateService.isK12Customer() || companyStateService.isHigherEdCustomer()) {
              <td class="table-body__cell">
                <span class="u_ellipsis-md">
                  {{ schedule.assignedUsers && schedule.assignedUsers.length > 0 ? (schedule.assignedUsers[0] | username) : '' }}
                </span>
                @if (schedule.assignedUsers && schedule.assignedUsers.length > 1) {
                  <span class="badge badge-primary u_clickable ml-2"
                    [popover]="assignedToPopover"
                    (onShown)="popoverText(schedule.assignedUsers)"
                    containerClass="madero-style"
                    outsideClick="true"
                    placement="bottom right">
                    +{{ schedule.assignedUsers.length - 1 }}
                  </span>
                }
              </td>
            }
          </tr>
        }
        <!-- If no search results -->
        @if (!schedules.loadingItems && schedules.items.list.length === 0) {
          <tr class="placeholder">
            <td colspan="6">
              <div class="tw-flex tw-flex-col tw-items-center tw-w-full tw-mb-20">
                @if(search.query === undefined) {
                  <img src="../images/empty-schedules.svg">
                  <h3>Create your first schedule</h3>
                  <p class="tw-block tw-w-1/2 tw-text-center tw-text-gray-600 tw-text-wrap">
                    A schedule is a playlist of multiple presentations with rules as to what days and times to play. Click Add Schedule to create your first schedule.
                  </p>
                  <a mat-button color="primary" id="scheduleAddButton" uiSref="apps.schedules.add">
                    Add Schedule
                  </a>
                } @else {
                  <img src="../images/no-results.svg">
                  <h3>No results found</h3>
                  <p class="tw-block tw-w-1/2 tw-text-center tw-text-gray-600 tw-text-wrap">
                    No items match your search criteria. Try to search by another keyword.                    </p>
                  <button mat-button
                    color="primary"
                    (click)="searchFilter.reset()">
                      Clear results
                  </button>
                }
              </div>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </cdk-virtual-scroll-viewport>
</div>
