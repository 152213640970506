<ng-template #assignedToPopover><div [innerHtml]="assignedToUsers"></div></ng-template>
<main class="madero-style tw-flex tw-flex-col xl:!tw-flex-row-reverse">
  <weekly-templates></weekly-templates>
  <div class="tw-w-full tw-px-6">
    <div resizeObserver (resizeEvent)="onHeaderResize($event)">
      <div class="app-header u_margin-md-top mb-5">
        <!-- App Title -->
        <h1 class="app-header-title" id="title">Presentations</h1>
      </div>
      <div id="errorBox" [shown]="presentations.apiError"
        class="madero-style alert alert-danger text-center u_margin-md-top" role="alert">
        <p><strong>{{presentations.errorMessage}}</strong></p>
        {{presentations.apiError}}
      </div>
      <div class="tw-flex tw-flex-col md:!tw-flex-row tw-py-8 tw-gap-8 light-border">
        <search-filter class="tw-w-full" #searchFilter [filterConfig]="filterConfig" [search]="search"
          [doSearch]="presentations.doSearch"></search-filter>
        <button id="presentationAddButton" (click)="editorService.addPresentationModal()" mat-flat-button
          color="primary" class="tw-min-w-80" *requireRole="'cp ce ap'">
          <mat-icon svgIcon="plus" aria-hidden="true" /> Add Presentation
        </button>
      </div>
      <batch-operations styles="xl:!tw-mr-[288px] xl:!tw-min-w-[725px]" [listObject]="presentations"></batch-operations>
    </div>
    <div
      #presentationListContainer
      rvSpinner="presentation-list-loader"
      [rvShowSpinner]="presentations.loadingItems || editorService.loadingPresentation || templateEditorFactory.loadingPresentation">
      <cdk-virtual-scroll-viewport #scrollViewport itemSize="68"
        class="scrollable-list horizontal-scroll rounded-rows">
        <table id="presentationListTable" class="table">
          <thead class="table-header">
            <tr class="table-header__row u_clickable">
              <th class="table-header__cell col-sm-4">
                <div class="flex-row fixed-width">
                  <mat-checkbox (click)="presentations.selectAll()" [checked]="search.selectAll"
                    [ariaLabel]="search.selectAll ? 'Deselect all' : 'Select all'" class="mr-2" />
                  <div class="u_clickable u_ellipsis-lg" id="tableHeaderName" (click)="presentations.sortBy('name')">
                    Name
                    @if (search.sortBy === 'name') {
                    <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa"
                      [ngClass]="{'fa-caret-up': !search.reverse , 'fa-caret-down': search.reverse }"></i>
                    }
                  </div>
                </div>
              </th>
              <th id="tableHeaderStatus" class="table-header__cell col-sm-2"
                (click)="presentations.sortBy('revisionStatusName')">
                Status
                @if (search.sortBy === 'revisionStatusName') {
                <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa"
                  [ngClass]="{'fa-caret-up': !search.reverse , 'fa-caret-down': search.reverse }"></i>
                }
              </th>
              <th id="tableHeaderSchedules" class="table-header__cell col-sm-2"
                (click)="presentations.sortBy('scheduleIds')">
                Schedules
                @if (search.sortBy === 'scheduleIds') {
                <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa"
                  [ngClass]="{'fa-caret-up': !search.reverse , 'fa-caret-down': search.reverse }"></i>
                }
              </th>
              <th tabindex="0" id="tableHeaderChangeDate" class="table-header__cell col-sm-2"
                (click)="presentations.sortBy('changeDate')" (keyup.enter)="presentations.sortBy('changeDate')">
                Last Modified
                @if (search.sortBy === 'changeDate') {
                <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa"
                  [ngClass]="{'fa-caret-up': !search.reverse , 'fa-caret-down': search.reverse }"></i>
                }
              </th>
              @if (companyStateService.isK12Customer() || companyStateService.isHigherEdCustomer()) {
              <th id="tableHeaderAssignedTo" class="table-header__cell col-sm-2"
                (click)="presentations.sortBy('assignedUsersValue')">
                Assigned To
                @if (search.sortBy === 'assignedUsersValue') {
                <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa"
                  [ngClass]="{'fa-caret-up': !search.reverse , 'fa-caret-down': search.reverse }"></i>
                }
              </th>
              }
            </tr>
          </thead>
          <tbody class="table-body">
            @if (!(!presentations.loadingItems && presentations.items.list.length === 0 && search.query ===
            undefined)) {
            <tr *cdkVirtualFor="let presentation of presentations.items.list; let idx = $index"
              [uiSref]="!isHtmlPresentation(presentation) ? 'apps.editor.workspace.artboard' : 'apps.editor.templates.edit'"
              [uiParams]="{presentationId: presentation.id}" class="table-body__row clickable"
              [class.selected]="presentation.selected">
              <td class="table-body__cell">
                <div class="flex-row">
                  <mat-checkbox (click)="$event.stopPropagation(); presentations.select(presentation)"
                    [checked]="presentation.selected"
                    [ariaLabel]="presentation.selected ? 'Deselect presentation' : 'Select presentation'"
                    class="mr-2" />
                  <a [uiSref]="!isHtmlPresentation(presentation) ? 'apps.editor.workspace.artboard' : 'apps.editor.templates.edit'"
                    [uiParams]="{presentationId: presentation.id}" (click)="$event.stopPropagation()"
                    class="tw-font-medium u_ellipsis-lg">
                    {{presentation.name}}
                  </a>
                </div>
              </td>
              <td class="table-body__cell">
                <mat-chip [ngClass]="{
                  'badge-green': presentation.revisionStatusName === 'Published',
                  'badge': presentation.revisionStatusName !== 'Published'
                }">
                  {{presentation.revisionStatusName}}
                </mat-chip>
              </td>
              <td class="table-body__cell tw-text-gray-700">{{!presentation.scheduleCount ? 'No schedules' :
                (presentation.scheduleCount + (presentation.scheduleCount === 1 ? ' Schedule' : ' Schedules'))}}</td>
              <td class="table-body__cell"><change-details [changeDate]="presentation.changeDate"
                  [changedBy]="presentation.changedBy" /></td>
              @if (companyStateService.isK12Customer() || companyStateService.isHigherEdCustomer()) {
              <td class="table-body__cell">
                <span class="u_ellipsis-md">
                  {{ presentation.assignedUsers && presentation.assignedUsers.length > 0 ?
                  (presentation.assignedUsers[0] | username) : '' }}
                </span>
                @if (presentation.assignedUsers && presentation.assignedUsers.length > 1) {
                <span class="badge badge-primary u_clickable ml-2" [popover]="assignedToPopover"
                  (onShown)="popoverText(presentation.assignedUsers)" containerClass="madero-style"
                  outsideClick="true" placement="bottom right">
                  +{{ presentation.assignedUsers.length - 1 }}
                </span>
                }
              </td>
              }
            </tr>
            }
            @if (!presentations.loadingItems && presentations.items.list.length === 0) {
            <tr class="placeholder">
              <td colspan="6">
                <div class="tw-flex tw-flex-col tw-items-center tw-w-full tw-mb-20">
                  @if (search.query === undefined) {
                  <img src="../images/empty-presentations.svg">
                  <h3>Customize your first presentation</h3>
                  <p class="tw-block tw-w-1/2 tw-text-center tw-text-gray-600 tw-text-wrap">
                    A presentation is the content you want to show on your display. Click "Add Presentation" to choose
                    a Template, and it will automatically be saved to this list.
                  </p>
                  <button id="presentationAddButton" *requireRole="'cp ce ap'" mat-button color="primary"
                    (click)="editorService.addPresentationModal()">
                    Add Presentation
                  </button>
                  } @else {
                  <img src="../images/no-results.svg">
                  <h3>No results found</h3>
                  <p class="tw-block tw-w-1/2 tw-text-center tw-text-gray-600 tw-text-wrap">
                    No items match your search criteria. Try to search by another keyword. </p>
                  <button mat-button color="primary" (click)="searchFilter.reset()">
                    Clear results
                  </button>
                  }

                </div>
              </td>
            </tr>
            }
          </tbody>

        </table>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</main>