import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class TimeInputComponent {
  
  @Input() min?: number = 1;
  @Input() max?: number;
  @Input() name?: string;
  @Input() label?: string = 'Minutes';
  @ViewChild('timeInput') timeInput: NgModel;

  _value: number = 1;
  get value(): number {
    return this._value;
  }
  @Input() set value(n: number) {
    let validValue = n === undefined || n === null ? 1 : n;
    validValue = Math.max(this.min, validValue);
    this._value = Math.round(validValue);
  }

  @Output() updateValue: EventEmitter<number> = new EventEmitter<number>();

  @Input() disabled: boolean;

  onChange() {
    if (this._value < this.min) {
      this._value = this.min;
    }
    this.updateValue.emit(this.value);
  }
}
