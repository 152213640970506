<div class="madero-style input-group rise-date-picker radio-nested-input-group mt-3 ml-auto w-75">
  <input type="number" class="form-control" id="time-input"
   #timeInput="ngModel" [name]="name" [(ngModel)]="value" (input)="onChange()" [min]="min" [max]="max" [disabled]="disabled"
  />
  <span class="input-group-btn">
    <label
      type="button"
      for="time-input"
      class="btn timeInput-label-container dropdown-toggle"
    >
      {{ label }}
    </label>
  </span>
</div>
